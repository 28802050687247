<template>
    <div class="player_card">
        <img src="../assets/images/ava1.jpg" alt="">
        <div class="player_info">
            <span class="nickname">{{ nickname }}</span>
            <span class="collected">Карт собрано: {{ cardsCollect }}<span class="adding">{{ adding>0?'+'+adding:adding }}</span></span>
        </div>
    </div>
</template>
<script>
export default {
  name: 'PlayerCard',
  props: {
    nickname: {
        type: String,
        default: "Вы"
    },
    cardsCollect: {
        type: Number,
        default: 0
    },
    adding: {
        type: Number,
        default: 0
    }
  }
  }
</script>
<style>
.player_card{
    display: flex;
    justify-content: space-around;
    position: relative;
}
.player_card img{
    width: 24%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #cad52a;
}
.player_info{
    font-size: 28px;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.adding{
    text-shadow: 2px 0 #fff, 0 -2px #fff, -2px 0 #fff, 0 2px #fff;
    display: none;
    padding-left: 5px;
}
.hidden{ animation: hint_hide 2s linear;display: block;}
  @keyframes hint_hide{
    0%{transform: translate(0%,0%);}
    15%{transform: translate(0%,10%);}
    85%{transform: translate(0%,15%);} 
    100%{transform: translate(0%,30%);}
  }
</style>
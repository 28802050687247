<template>
    <div class="info">
        <div>
        Немного поделюсь планами разработки: осталось реализовать последний тип <i>бонусных</i> карт. И после этого, я начну
        работу над добавлением онлайн-комнат! И вы сможете играть вместе со своими друзьями!
        <span>12.06.2024</span>
        </div>
        <hr>
        <div>
        В последнее время над игрой была проделана большая работа: устранены большинство багов, добавлено много новых карт,
        а также возможностей и изменений. Буду рад получить обратную связь! Пишите о ваших впечатлениях на сайте и в соц. сетях.
        <span>06.06.2024</span>
        </div>
        <hr>
        <div>
        <a href="https://www.tiktok.com/@amazingfedos/video/7368563230970498309?lang=ru-RU">
            <img align="left" src="../assets/images/news1.jpg">
        </a>
        В новом видео на своем тик-ток аккаунте, я рассказал об оригинальных правилах игры в карточки человека-паука!
        Переходите по ссылке(картинка), чтобы ознакомиться с видео. В дальнейшем данные режимы будут добавлены на сайт игры. 
        <span>14.05.2024</span>
        </div>
        <hr>
        <div>
        <a href="https://www.tiktok.com/@amazingfedos/video/7366733411567897861?lang=ru-RU">
            <img align="right" src="../assets/images/news2.jpg">
        </a>
        Лишь благодаря энтузиастам о карточках Человека-паука еще помнят! Один из таких собственноручно занимается разработкой
        новой, уникальной линейкой карточек. Обзор на них, можете посмотреть в моем тик-токе. А для большей информации о карточках,
        переходите в <a href="https://vk.com/cards_s_m">группу вконтакте</a>. 
        <span>9.05.2024</span>
        </div>
        <hr>
        <div>
        Вернувшись после долгого отсутствия, я снова принимаюсь за работу над проектом!
        <span>30.04.2024</span>
        </div>
        <hr>
        <div>
        Игра "Карточки Человека-Паука" еще находится в <i>режиме разработки</i>. Сейчас вы можете сразиться с компьютерным противником в одиночном режиме. В дальнейшем планируется онлайн игра с другими игроками, различные режимы игры и еще больше карточек из детства! Подробнее узнать о проекте и ходе разработки можете в <a href="https://t.me/fedos_cards">Телеграмм канале</a><br> Там же вы можете оставлять свои отзывы о проекте и предлагать свои идеи, и быть может даже внести вклад в развитие проекта. 
        <span>20.10.2023</span>
        </div>
        <hr>
        <div>
        Изображение в главном меню взято из видео с ютуб канала <a href="https://www.youtube.com/@Kolos_life">KoLoS Живьём</a>
        <span>20.10.2023</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'InfoBlock'
}
</script>
<style>

</style>
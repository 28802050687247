<template>
    <div class="game_rules">
        <a class="close_rules" @click="closeRules">+</a> 
        <div class="game_rules_wrapper">
            <div class="rules_text">
                <p><b>Цель игры:</b> Собрать больше карт, чем противник</p>
                <p><b>Как играть:</b> Каждая боевая карточка имеет несколько характеристик.
                Сражение происходит с той характеристикой, которую выбрал
                игрок/противник в начале раунда. Для выбора характеристики 
                нажмите на карту, а затем на саму характеристику. Побеждает
                игрок/противник, чья карта оказалась самой сильной в раунде. По ходу игры все станет понятно</p>
                <p><b>Игровые ситуации:</b>
                    <ul>
                        <li>
                            <i>- Неспособность победить первую карту:</i> В случае, когда игрок не может противостоять первой выбранной карте противника, он должен отдать одну из своих карт противнику. Если же противник не в состоянии победить карту игрока: игрок сам выбирает, какую карту отдаст ему противник. В таком случае, карты противника выделятся <span>красным цветом</span>
                        </li>
                        <li>
                            <i>- Сдаться:</i> Возможность сдаться может быть весьма полезной. В случаях, когда игрок желает приберечь карту, но никакая другая карта не способна победить текущую карту противника - сдаться и отдать противнику ненужную, будет отличным вариантом
                        </li>
                        <li>
                            <i>- Завершить раунд:</i> Если игрок не желает продолжать раунд, он может завершить его преждевременно нажав кнопку "Завершить раунд". В таком случае, карты, которые были в игре, отходят к противнику, а игрок сохраняет имеющиеся у него карты
                        </li>
                    </ul> 
                </p>
            </div>
        </div>
    </div>
</template>
  <script>
  export default {
    name: 'GameRules',
    components: { },
    methods:{
        closeRules(){
            this.$emit('closeRules')
        }
    }
  }
  </script>
  <style>
  .game_rules{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 75%;
    background: rgba(0,0,0,0.9);
    color: #fff;
    padding: 25px;
    font-size: 26px;
    border-radius: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}
.close_rules{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    transform: rotate(45deg);
    font-size: 35px;
    text-decoration: none;
    cursor: pointer;
}
.rules_text{
    overflow-y: auto;
    max-height: 100%;
    font-size: 24px;
}
.rules_text p{
    padding-bottom: 25px;
}
.rules_text b{
    color: #cad52a;
    font-weight: 700;
}
.rules_text i{
    font-style: italic;
}
.rules_text li{
    padding-bottom: 10px;
}
.rules_text span{
    color: red;
}
.game_rules::-webkit-scrollbar {
  width: 12px;               /* ширина scrollbar */
}
.game_rules::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.9);       /* цвет дорожки */
}
.game_rules::-webkit-scrollbar-thumb {
  background-color: #cad52a;    /* цвет плашки */
  border-radius: 20px;       /* закругления плашки */
  border: 3px solid rgba(0,0,0,0.9);  /* padding вокруг плашки */
}
  </style>
import { createRouter, createWebHistory } from 'vue-router';
import Main from '../src/components/MainPage.vue';
import CardsGallery from '../src/components/AllCards.vue';

const routes = [
  {
    path: '/',
    component: Main,
  },
  {
    path: '/gallery',
    component: CardsGallery,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import {createStore} from 'vuex'
import SpiderCards from '../src/assets/cards.json'
import TurtlesCards from '../src/assets/turtles.json'

export default createStore({
    state:{
        spiderCards: SpiderCards,
        turtlesCards: TurtlesCards
    },
    getters:{
        
    },
    mutations:{},
    actions:{},
    modules:{},
})
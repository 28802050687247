<template>
    <div class="game_version">
        <div class="game_version_wrapper">
            <a class="close_version" @click="closeVersion">+</a> 
            <div class="version_text">
                <h3>alfa version 0.22.265</h3>
                <ul>
                    <li>Добавлено 5 БОНУСНЫХ карточек Человека-паука</li>
                    <li>Исправлен ряд багов</li>
                    <li>Улучшена оптимизация</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.21.260</h3>
                <ul>
                    <li>Добавлено 10 БОНУСНЫХ карточек Человека-паука</li>
                    <li>Добавлено 15 карточек Человека-паука</li>
                    <li>Добавлена возможность оставить отзыв о проекте</li>
                    <li>Добавлена загрузка при запуске игры</li>
                    <li>Исправлен ряд багов</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.20.235</h3>
                <ul>
                    <li>Изменен геймплей</li>
                    <li>Добавлены новые визуальные эффекты</li>
                    <li>Добавлено 16 новых карт Человека-паука</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.19.219</h3>
                <ul>
                    <li>Исправлен ряд багов</li>
                    <li>Улучшена оптимизация</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.18.219</h3>
                <ul>
                    <li>Добавлен счетчик побед на сайт</li>
                    <li>Добавлены доп. сообщения во время игры</li>
                    <li>Улучшен информационный(новостной) блок</li>
                    <li>Добавлено 2 новых карты Человека-паука</li>
                    <li>Добавлены визуальные эффекты для редких и суперредких карт</li>
                    <li>Исправлен баг с карточкой Сплава</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.17.217</h3>
                <ul>
                    <li>Добавлено 10 БОНУСНЫХ карточек Человека-паука</li>
                    <li>Добавлен информационный блок на сайт</li>
                    <li>Исправлены игровые баги</li>
                    <li>Добавлены новые донатеры</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.16.207</h3>
                <ul>
                    <li>Добавлено 5 новых карточек Человека-паука</li>
                    <li>Добавлена функция "Сдаться"</li>
                    <li>Максимальное количество карт в одной руке снижено до 5</li>
                    <li>Исправлены игровые баги</li>
                    <li>Исправлены визуальные баги</li>
                    <li>Добавлен новый донатер</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.15.202</h3>
                <ul>
                    <li>Добавлено 28 новых карточек Человека-паука</li>
                    <li>Добавлено 20 новых карточек Черепашек-нинздя</li>
                    <li>Исправлены визуальные баги</li>
                    <li>Добавлен новый донатер</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.14.174</h3>
                <ul>
                    <li>Добавлено 25 новых карточек Человека-паука</li>
                    <li>Добавлены ссылки на соц.сети</li>
                    <li>Исправлены баги с сохранением настроек</li>
                    <li>Исправлены визуальные баги</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.14.149</h3>
                <ul>
                    <li>Добавлено 11 новых карточек Человека-паука</li>
                    <li>Добавлена настройка карты Сплава</li>
                    <li>Исправлены звуковые баги</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.13.138</h3>
                <ul>
                    <li>Исправлены визуальные баги</li>
                    <li>Исправлены игровые баги</li>
                    <li>Добавлено звуковое сопровождение игры</li>
                    <li>Добавлена иконка сайта (favicon)</li>
                    <li>Возможность запомнить настройки для дальнейшей игры</li>
                    <li>Возможность выйти из игры назад в главное меню</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.12.138</h3>
                <ul>
                    <li>Исправлены визуальные баги</li>
                    <li>Добавлено 30 новых карточек Человека-паука</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>alfa version 0.12.108</h3>
                <ul>
                    <li>Добавлено окно с обновлениями</li>
                    <li>Добавлен режим игры в карточки Черепашек-нинздя</li>
                    <li>Скрыл нерабочие настройки (которые в разработке)</li>
                    <li>Проект переходит в стадию alfa</li>
                </ul>
                <hr>
            </div>
            <div class="version_text">
                <h3>pre-alfa version 0.11.108</h3>
                <ul>
                    <li>Добавлены новые игровые карты</li>
                    <li>Установлена метрика на сайт</li>
                    <li>Исправлены визуальные баги</li>
                </ul>
                <hr>
            </div>
        </div>
    </div>
</template>
  <script>
  export default {
    name: 'GameVersion',
    components: { },
    methods:{
        closeVersion(){
            this.$emit('closeVersion')
        }
    }
  }
  </script>
  <style>
.game_version{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 75%;
    background: rgba(0,0,0,0.9);
    color: #fff;
    padding: 25px;
    font-size: 26px;
    border-radius: 20px;
    overflow-y: auto;
}
.close_version{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    transform: rotate(45deg);
    font-size: 35px;
    text-decoration: none;
    cursor: pointer;
}
.game_version::-webkit-scrollbar {
  width: 12px;               /* ширина scrollbar */
}
.game_version::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.9);       /* цвет дорожки */
}
.game_version::-webkit-scrollbar-thumb {
  background-color: #cad52a;    /* цвет плашки */
  border-radius: 20px;       /* закругления плашки */
  border: 3px solid rgba(0,0,0,0.9);  /* padding вокруг плашки */
}
.version_text h3{
    color: #cad52a;
}
.version_text li:before {
   content: "- ";
}
  </style>
<template>
  <div 
  class="mainscreen"
  @click="closeSettings"
  >
        <button
        @click.stop="openModal" 
        class="glow-on-hover" 
        type="button"
        >Играть</button>
    </div>
</template>
<script>
export default {
  name: 'MainScreen',
  components: {
    
  },
  methods:{
    openModal(){
        this.$emit('openGameSettings')
      },
    closeSettings(){
      this.$emit('closeSettings')
    }
  }
}
</script>
<style>
#app {
  
}
</style>
<template>
  <div class="gallery">
  <header>
    <nav>
      <router-link to="/">На главную</router-link>
    </nav>
  </header> 
  <div class="gallery_wrapper">
    <GalleryCard   
      v-for="card in this.cards"
      :key="card.number"
      :card="card"
    />
  </div>
  <!-- <div class="gallery_description">
    <img class="chest_img" src="../assets/images/chest.png" alt="">
  </div> -->
  </div>
</template>
  <script>
  import GalleryCard from "../components/GalleryCard.vue"
  export default {
    name: 'AllCards',
    components: { 
      GalleryCard
     },
    methods:{ },
    props:{ },
    data(){
      return{
        cards: this.$store.state.spiderCards
      }
    }
  }
  </script>
  <style>
.gallery{
  background-image: url('../assets/galleryfon.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
}
.gallery_wrapper{
  padding: 0 10%;
  padding-top: 100px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
}
.gallery_description{
  position: relative;
  width: 100%;
}
.chest_img{
  position: absolute;
  left: 0;
}
@media(max-width: 500px){
  .gallery_wrapper{
    padding: 40px 5% !important;
  }

}
  </style>
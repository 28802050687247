<template>
    <div class="win_counter">
        <span class="wins">Всего побед: <span>{{ this.wins_count }}</span></span>
    </div>
</template>
<script>
export default {
  name: 'WinCounter',
  props: {
    wins_count: {
        type: Number,
        default: 0
    }
  }
}
</script>
<style>
.win_counter{
    position: absolute;
    left: 2%;
    top: 40%;
    color: #fff;
    transform: translate(0%,-50%);
    z-index: 0;
}
.wins{
    font-family: "HeadingProTrial";
    font-size: 30px;
}
.wins span{
    font-family: "Mohave-BoldItalic";
}
@media(max-width:500px){
    .wins{
        font-size: 20px;
        text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
    }
    .win_counter{
        top: 70%;
    }
}
</style>
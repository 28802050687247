<template>
    <div class="loading" :class="{none : !loading.zoneLoad&&!loading.cardsLeftLoad}">
    <img :src="this.src">
    Идет загрузка...
    </div>
</template>
<script>
export default {
    name: 'LoadingScreen',
    data(){
        return {
            src:require("../assets/images/ref.jpg")
        }
    },
    props:{
        loading:{
            type: Object,
        },
        game:{
            type: Number,
            default: 1
        }
    },
    mounted(){
        if(this.game==2){
            this.src=require("../assets/images/turt.jpg")
        }
    }
}
</script>
<style>
.loading{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 40px;
    z-index: 200;
}
.loading.none{
    z-index: 0 !important;
    display: none;
}
.loading img {
    width: 65px;
    height: 100px;
    margin-bottom: 20px;
    animation: roteting 10s linear infinite;
}
@keyframes roteting {
    0%{transform: rotate(-360deg)};
    100%{transform: rotate(0deg)}
}
</style>
<template>
  <div class="game_settings">
        <a class="close_settings" @click="closeSettings">+</a>    
        <img v-if="this.spider_game" class="spider_cards_img" src="../assets/images/ref.jpg" alt="">
        <img v-if="this.turtles_game" class="turtles_cards_img" src="../assets/images/turt.jpg" alt="">
        <div class="game_setting_wrapper">
            <div class="wrap game_type" style="padding-bottom: 30px;">
                <span @click="setSpiderCards" class="spider active">Человек-Паук</span>
                <span @click="setTurtlesCards" class="turtles">Черепашки-ниндзя</span>
            </div>
            <div class="wrap">
                <span>Звук: </span>
                <input 
                class="use_sound" 
                type="checkbox"
                v-model="use_sound">
            </div>
            <div class="wrap">
                <span>Карт в одной руке: </span>
                <input 
                class="cards_in_hand" 
                type="range" 
                :value="cards_in_hand"
                @input="cards_in_hand = $event.target.value"
                min="2" max="5" step="1">
                <span class="cards_in_hand_out">{{ cards_in_hand }}</span>
            </div>
            <div class="wrap">
                <span>Карт всего в игре: </span>
                <input 
                class="cards_in_game" 
                type="range" 
                :value="cards_in_game"
                @input="cards_in_game = $event.target.value"
                min="30" :max="this.maxCardsLength" step="1">
                <span class="cards_in_hand_out">{{ cards_in_game }}</span>
            </div>
            <div v-if="this.spider_game" class="wrap">
                <span>Сплав самый сильный: </span>
                <input 
                class="splav_strong" 
                type="checkbox"
                v-model="splav_strongest">
            </div>
            <div class="wrap">
                <span>Запомнить настройки: </span>
                <input 
                class="remember" 
                type="checkbox"
                v-model="remember">
            </div>
            <div v-if="false" class="wrap">
                <span>Использовать бонусы: </span>
                <input 
                class="use_bonus" 
                type="checkbox"
                v-model="use_bonus">
                <span style="font-size: 14px;">(функция в разработке) </span>
            </div>
            <button
                @click="postSettings"
                class="glow-on-hover" 
                type="button">
                Начать
            </button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'GameSettingWindow',
  props:{
    gameSetting:{
      type: Object
    }
  },
  methods:{
    closeSettings(){
        this.$emit('closeSettings')
    },
    postSettings(){
        let gameId = 1;
        if(this.spider_game){ gameId = 1; }
        if(this.turtles_game){ gameId = 2; }
        this.$emit('postGameSettings',this.cards_in_hand, this.use_bonus, this.cards_in_game, gameId, this.use_sound, this.remember, this.splav_strongest);
    },
    setSpiderCards(){
        this.spider_game = true;
        this.turtles_game = false
        this.maxCardsLength = this.$store.state.spiderCards.length;
        let spider = document.querySelector('.spider');
        spider.classList.add('active');
        let turtles = document.querySelector('.turtles');
        turtles.classList.remove('active');
        this.cards_in_game = 30;
    },
    setTurtlesCards(){
        this.turtles_game = true;
        this.spider_game = false;
        this.maxCardsLength = this.$store.state.turtlesCards.length;
        let spider = document.querySelector('.spider');
        spider.classList.remove('active');
        let turtles = document.querySelector('.turtles');
        turtles.classList.add('active');
        this.cards_in_game = 30;
    }
  },
  data(){
      return{
          cards_in_hand: 3,
          cards_in_game: 30,
          use_bonus:false,
          use_sound:true,
          remember:false,
        splav_strongest:false,
        spider_game:true,
        turtles_game:false,
        maxCardsLength: this.$store.state.spiderCards.length,
    }
  },
  mounted(){
    let cards_in_hand = document.querySelector('.cards_in_hand');
    if(window.screen.width<426){
        cards_in_hand.max = 4
    }
    if(this.gameSetting.cards_in_hand){
        if(this.gameSetting.gameId==1){
            this.setSpiderCards()
        }
        if(this.gameSetting.gameId==2){
            this.setTurtlesCards()
        }
        if(window.screen.width<426){
            cards_in_hand.max = 4
            if(this.gameSetting.cards_in_hand>4){
                this.cards_in_hand = 3
            } else {
                this.cards_in_hand = this.gameSetting.cards_in_hand
            }
        } else {
            this.cards_in_hand = this.gameSetting.cards_in_hand
        }
        this.cards_in_game = this.gameSetting.cards_in_game
        this.use_bonus = this.gameSetting.use_bonus
        this.use_sound = this.gameSetting.use_sound
        this.remember  = this.gameSetting.remember
        this.splav_strongest  = this.gameSetting.splav
    }
  }
}
</script>
<style scaled>
.game_settings {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 60%;
    background: rgba(0,0,0,0.9);
    color: #fff;
    padding: 25px;
    font-size: 26px;
    border-radius: 20px;
    overflow: hidden;
}
.turtles_cards_img,
.spider_cards_img{
    position: absolute;
    width: 40%;
    transform: rotate(-30deg) translate(0%, 10%);
    opacity: 0.5;
    filter: blur(7px);
}
.turtles_cards_img{
    transform: rotate(35deg) translate(0%, 10%);
    right: 0;
}
.glow-on-hover{
    margin-top: 20px;
}
.close_settings{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    transform: rotate(45deg);
    font-size: 35px;
    text-decoration: none;
    cursor: pointer;
}
.game_setting_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 75%;
  position: relative;
  margin: 0 auto;
}
.game_type span{
    cursor: pointer;
    font-family: "HeadingProTrial";
}
.game_type span.active{
    color: #cad52a;
    text-decoration: underline;
}
.splav_strong,
.remember,
.use_sound{
    width: 30px;
    margin: 0;
}
</style>
<template>
    <img :src="require(`../assets/cards/${card.image}`)" alt="">
</template>
<script>
export default {
    name: 'GalleryCard',
    components: { },
    methods:{ },
    props:{ 
        card:{
            type: Object
        }
    }
}
</script>
<style>
.gallery img{
    box-shadow: 1px 1px 1px 2px #000;
    width: 13%;
    margin: 10px;
    border-radius: 10px;
    transition: all 0.4s
}
.gallery img:hover{
    box-shadow: 0 0 15px 5px #cad52a;
    transform: scale(1.1);
    transition: all 0.4s
}
@media(max-width: 500px){
    .gallery img{
        width: 30%;
        margin: 5px 1%;
    }
    .gallery img:hover{
        box-shadow: 0 0 10px 5px #cad52a;
        transform: scale(1.05);
    }
}
</style>
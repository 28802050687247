<template>
    <div class="card"
         v-bind:class="{ strongest: strongestCard, splav: this.splav_card, rare:rare, super:superr,ultra:ultra,save:cansave&&this.card.save }"
         @click="takeThisCard"
         
         :style="{ backgroundImage : 'url(' + require(`@/assets/cards/${card.image}`) +')'}">
         <!-- <img :src="require(`@/assets/cards/${card.image}`)"> -->
    <div 
    v-if="!this.playergives && this.gametype==1"
    class="card_stats" :class="{ none_pad : this.card.steal || this.card.save }">
      <ul>
        <li style="text-align:center;padding:0" @click="saveCards" v-if="this.card.save">Спасти Всех!</li>
        <li style="text-align:center;padding:0" @click="stealCards" v-if="this.card.steal">Украсть<span>{{this.card.steal>0?this.card.steal:this.card.steal*-1 }}</span>{{ this.card.steal>0?'':' у каждого' }}</li>
        <li
          v-if="this.card.stats[0] || this.card.number==142"
          v-bind:class="{ strong : maxValue==card.stats[0]}"
          @click="selectedStat(0)">
          Интеллект: <span>{{ card.stats[0] }}</span>
        </li>
        <li
          v-if="this.card.stats[1] || this.card.number==142"
          v-bind:class="{ strong : maxValue==card.stats[1]}"
          @click="selectedStat(1)">
          Сила: <span>{{ card.stats[1] }}</span></li>
        <li
          v-if="this.card.stats[2] || this.card.number==142"
          v-bind:class="{ strong : maxValue==card.stats[2]}"
          @click="selectedStat(2)">Скорость и Ловкость: <span>{{ card.stats[2] }}</span></li>
        <li
          v-if="this.card.stats[3] || this.card.number==142"
          v-bind:class="{ strong : maxValue==card.stats[3]}"
          @click="selectedStat(3)">Особые умения: <span>{{ card.stats[3] }}</span></li>
        <li
          v-if="this.card.stats[4] || this.card.number==142"
          v-bind:class="{ strong : maxValue==card.stats[4]}"
          @click="selectedStat(4)">Бойцовские навыки: <span>{{ card.stats[4] }}</span></li>
      </ul>
    </div>
    <div 
    v-if="!this.playergives && this.gametype==2"
    class="card_stats">
      <ul>
        <li
          v-bind:class="{ strong : maxValue==card.stats[0]}"
          @click="selectedStat(0)">
          Сила: <span>{{ card.stats[0] }}</span>
        </li>
        <li
          v-bind:class="{ strong : maxValue==card.stats[1]}"
          @click="selectedStat(1)">
          Ловкость: <span>{{ card.stats[1] }}</span></li>
        <li
          v-bind:class="{ strong : maxValue==card.stats[2]}"
          @click="selectedStat(2)">Мастерство: <span>{{ card.stats[2] }}</span></li>
        <li
          v-bind:class="{ strong : maxValue==card.stats[3]}"
          @click="selectedStat(3)">Смекалка: <span>{{ card.stats[3] }}</span></li>
      </ul>
    </div>
    <div 
    v-if="this.playergives"
    class="card_action_button"><button @click="playerGiveCard" >Отдать карту</button></div>
    </div>
</template>
<script>
export default {
  name: 'SingleCard',
  mounted(){
    if(this.card.number==142 && this.splav && this.card.stats[0]==1000){
      this.splav_card = true
    }
    let max = Math.max.apply(null, this.card.stats);
    this.maxValue = max;
    if(this.card.rarity){
      if(this.card.rarity=='r'){ this.rare = true; }
      if(this.card.rarity=='s'){ this.superr = true; }
      if(this.card.rarity=='u'){ this.ultra = true; }
    }   
  },
  data(){
    return{
      strongestCard: false,
      maxValue: 0,
      splav_card: false,
      rare: false,
      superr: false,
      ultra: false,
     }
  },
  props:{ 
    card:{ type: Object },
    strongest:{ type: Object },
    current:{ type: Object },
    playergives: {type: Boolean, default: false},
    gametype: {type: Number, default: 1},
    cantakecard: {type: Boolean, default: false},
    whoplaysround: {type : Boolean, default: false},
    splav: {type : Boolean, default: false},
    cansave: {type : Boolean, default: false},
  },
  methods:{
    selectedStat(id){
      if(this.whoplaysround){
        if(this.current.name){
          for(let i=0;i<this.card.stats.length;i++){
            if(this.current.id == 0){
              if(this.card.stats[0]>this.current.value){
                if(this.gametype==1){
                  this.$emit('getStat', {name:"Интеллект",value:this.card.stats[0],id:0}, this.card)
                  break; 
                }
                if(this.gametype==2){
                  this.$emit('getStat', {name:"Сила",value:this.card.stats[0],id:0}, this.card)
                  break; 
                }
              }
            }
            if(this.current.id == 1){
              if(this.card.stats[1]>this.current.value){
                if(this.gametype==1){
                  this.$emit('getStat', {name:"Сила",value:this.card.stats[1],id:1}, this.card)
                  break; 
                }
                if(this.gametype==2){
                  this.$emit('getStat', {name:"Ловкость",value:this.card.stats[1],id:1}, this.card)
                  break; 
                }
              }
            }
            if(this.current.id == 2){
              if(this.card.stats[2]>this.current.value){
                if(this.gametype==1){
                  this.$emit('getStat', {name:"Скорость и Ловкость",value:this.card.stats[2],id:2}, this.card)
                  break; 
                }
                if(this.gametype==2){
                  this.$emit('getStat', {name:"Мастерство",value:this.card.stats[2],id:2}, this.card)
                  break; 
                }
              }
            }
            if(this.current.id == 3){
              if(this.card.stats[3]>this.current.value){
                if(this.gametype==1){
                  this.$emit('getStat', {name:"Особые умения",value:this.card.stats[3],id:3}, this.card)
                  break; 
                }
                if(this.gametype==2){
                  this.$emit('getStat', {name:"Смекалка",value:this.card.stats[3],id:3}, this.card)
                  break; 
                }
              }
            }
            if(this.current.id == 4){
              if(this.card.stats[4]>this.current.value){
                this.$emit('getStat', {name:"Бойцовские навыки",value:this.card.stats[4],id:4}, this.card)
                break; 
              }
            }
          }
      } else {
          if(id==0){
            if(this.gametype==1){
              this.$emit('getStat', {name:"Интеллект",value:this.card.stats[0],id:0}, this.card) 
            }
            if(this.gametype==2){
              this.$emit('getStat', {name:"Сила",value:this.card.stats[0],id:0}, this.card) 
            }
          }
          if(id==1){
            if(this.gametype==1){
              this.$emit('getStat', {name:"Сила",value:this.card.stats[1],id:1}, this.card) 
            }
            if(this.gametype==2){
              this.$emit('getStat', {name:"Ловкость",value:this.card.stats[1],id:1}, this.card) 
            }
          }
          if(id==2){
            if(this.gametype==1){
              this.$emit('getStat', {name:"Скорость и Ловкость",value:this.card.stats[2],id:2}, this.card)
            }
            if(this.gametype==2){
              this.$emit('getStat', {name:"Мастерство",value:this.card.stats[2],id:2}, this.card) 
            }
          }
          if(id==3){
            if(this.gametype==1){
              this.$emit('getStat', {name:"Особые умения",value:this.card.stats[3],id:3}, this.card) 
            }
            if(this.gametype==2){
              this.$emit('getStat', {name:"Смекалка",value:this.card.stats[3],id:3}, this.card) 
            }
          }
          if(id==4){
            this.$emit('getStat', {name:"Бойцовские навыки",value:this.card.stats[4],id:4}, this.card) 
          }
        }
      }
     },
     stealCards(){
      this.$emit('stealCard', this.card);
     },
     saveCards(){
      this.$emit('saveCard',this.card);
     },
     takeThisCard(){
      if(this.cantakecard){
        this.$emit('takeThisCard', this.card);
      }
     },
     playerGiveCard(){
      if(this.playergives){
        this.$emit('playerGiveCard', this.card)
      }
     }
  },
  watch:{
    strongest:{
      immediate: true,
      handler(){
        if(this.strongest){
          let maxofstrong = Math.max.apply(null, this.strongest.stats);
          let maxofcard = Math.max.apply(null, this.card.stats);
          if(maxofstrong==maxofcard){
            this.strongestCard = true;
          } else {
            this.strongestCard = false;
          }
        }
      }
    },
    // strongestCard:{
    //   immediate: true,
    //   handler(){
    //     let strong = document.querySelector('.strongest');
    //     if(strong){
    //       console.log(strong);
    //       strong.classList.remove('strongest')
    //     }
    //   }
    // }
  }
}
</script>
<style>
.bot_cards .card:after,
.bot_cards .card:before{
  display:none;
}
.play_zone .card{
  overflow: hidden;
  position: relative;
}
.game_zone.turtles .card{
  height: 195px;
}
.card{
  width: 140px;
  height: 210px;
  /* border: 1px solid #fff; */
  box-shadow: 1px 1px 1px 2px #000;
  margin: 5px;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  animation-name: appearence;
  animation-duration: 600ms;
}
@keyframes appearence {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.card.strongest{
  box-shadow: 0 0 15px 5px #cad52a;
}
.card.save{
  box-shadow: 0 0 15px 5px #00cfde !important;
  }
.card.selected .card_stats{
  display: flex;
  transition: all 0.4s;
}
.card_stats{
  transition: all 0.4s;
  position: absolute;
  width:140px;
  border-radius: 10px;
  height: 100px;
  justify-content: space-around;
  flex-direction: column;
  font-weight: 700;
  font-size: 12px;
  bottom: 0;
  transform: translate(-0%,100%);
  align-items: center;
  display: none;
}
.card_stats li{
  position: relative;
  padding-bottom: 4px;
  text-align: right;
  text-transform: uppercase;
  font-family: "HeadingProTrial";
  line-height: .7;
  /* color: #e3d17d;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  */
}
.card_stats li span{
  padding: 0 0 3px 6px;
  font-family: "Mohave-BoldItalic";
  font-style: italic;
}
.card_stats li.strong{
  text-shadow: #cad52a 1px 0 10px;
}
.card_stats li:hover{
  cursor: pointer;
}
.card_stats li:after{
  content:"";
  position: absolute;
  left: 0;
  bottom: 0;
  display: none;
  width: 100%;
  height: 2px;
  background-color: #cad52a;
}
.card_stats li:hover:after{
  display: block;
}
.play_zone .card:last-child{
  box-shadow: 0 0 30px 5px #16c8c2 !important;
}
.card_action_button{
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(0, 40px);
  width: 140px;
}
.card_action_button button{
  width: 100%;
}
.splav:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
  }
  .card.rare:before{
    content: '';
    background: linear-gradient(45deg, #0000, #fff, #555, #fff, #111, #999, #777, #c8c8c8, #fff);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
  }
  .card.super:before{
    content: '';
    background: linear-gradient(45deg, #00d4fb, #fff, #007bbf, #fff, #00f0ff, #fff, #ffef00, #ffffff, #efff02);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
  }
.card.super:after,
.card.rare:after,
.splav:after {
  background: transparent;
}
@keyframes move-it {
  0% {
    background-position: initial;
  }
  100% {
    background-position: 100px 0px;
  }
}
.card.super:before,
.card.rare:before{
  opacity: .1;
}
.splav:before {
  opacity: .1;
}
.card.super:after,
.card.rare:after,
.splav:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: #cad52a; */
    left: 0;
    top: 0;
    border-radius: 10px;
}
@media(max-width: 500px){
  .game_zone.turtles .card{
    height: 120px;
    box-shadow: 1px 1px 1px 1px #000;
  }
  .game_zone.turtles .card.strongest{
    box-shadow: 0 0 15px 5px #cad52a;
  }
  .card{
    width: 90px;
    height: 135px;
    border-radius: 4px;
  }
  .card_stats.none_pad{
    transform: translate(50%,0%) !important;
    right: 50%;
  }
  @keyframes cansave {
    0%{
      transform: scale(1) translate(0, -110%);
    }
    50%{
      transform: scale(1.05) translate(0, -110%);
    }
    100%{
      transform: scale(1) translate(0, -110%);
    }
  }
}
</style>
<template>
    <div class="main_wrapper">
        <header>
            <nav>
                <a @click="this.rulesOpened=!this.rulesOpened">Как играть</a>
                <router-link to="/gallery">Галерея карточек</router-link>
                <a href="https://linktr.ee/amazingfedos" target="blank">Автор</a>
                <a @click="this.donatesOpened=!this.donatesOpened">Донатеры</a>
            </nav>
        </header>
        <MainScreen
            @openGameSettings="openGameSettings"
            @closeSettings="closeSettings"
        />
        <GameSettings
            v-if="this.openSettings"
            :gameSetting="this.gameSettings"
            @closeSettings="closeSettings"
            @postGameSettings="setGameSettings"
        />
        <WinCounter 
            :wins_count="this.wins_count"
        />
        <GameZone
        v-if="gameWindowOpened"
            :gameSettings="this.gameSettings"
            @gameOver="gaveOver"
            @winsCount="winsCount"
            :botNames="this.donaters"
        />
        <GameRules 
            v-if="this.rulesOpened"
            @closeRules="this.rulesOpened = false">
        </GameRules>
        <GameDonaters
            v-if="this.donatesOpened"
            @closeDonates="this.donatesOpened = false"
            :donaters="this.donaters"
        >
        </GameDonaters>
        <GameVersion
            v-if="this.versionOpened"
            @closeVersion="this.versionOpened = false"
        />
        <footer>
            <p>
                © All rights reserved. Product of the author Daniil Fedosenko. The product uses the intellectual property of companies such as <i>Marvel Entertainment</i> and <i>Eaglemoss Collections</i>, for <span>informational purposes only</span>!
            </p> 
        </footer>
        <div class="social">
          <a href="https://tiktok.com/@amazingfedos" class="social_link">
            <img class="link" src="../assets/images/tt.png">
          </a>
          <a href="https://t.me/fedos_cards" class="social_link">
            <img class="link" src="../assets/images/tg.png">
          </a>
          <a class="social_link">
            <img class="link" @click="infoOpened=!infoOpened" src="../assets/images/info.png">
            <InfoBlock v-if="this.infoOpened" />
          </a>
          <a class="social_link">
            <img class="link" @click="reviewOpened=!reviewOpened" src="../assets/images/message.png">
            <div v-if="this.reviewOpened" class="info review">
              <textarea v-if="!this.messageSending" :placeholder="this.messagePlaceholder" type="text" v-model="this.review"></textarea>
              <span v-if="this.messageSending">Сообщение отправляется...</span>
              <button v-if="!this.messageSending" @click="sendReview">Отправить</button>
            </div>
          </a>
        </div>
        <div class="version">
            <p @click="this.versionOpened=!this.versionOpened">alfa version {{ version }}</p>
        </div>
    </div>
</template>
  <script>
  /* eslint-disable */
  import MainScreen from './MainScreen.vue'
  import GameSettings from './GameSettingsWindow.vue'
  import GameZone from './Game.vue'
  import GameRules from './Rules.vue'
  import GameDonaters from './Donaters.vue'
  import GameVersion from './Version.vue'
  import WinCounter from './WinCounter.vue'
  import InfoBlock from './Info.vue'
  import packageJson from "../../package.json"
  import db from './firebaseInit'
  import firebase from 'firebase/compat/app';
  export default {
    name: 'MainPage',
    components: {
      MainScreen,
      GameSettings,
      GameZone,
      GameRules,
      GameDonaters,
      GameVersion,
      WinCounter,
      InfoBlock,
    },
    data(){
      return {
        openSettings:false,
        gameWindowOpened:false,
        rulesOpened: false,
        donatesOpened: false,
        versionOpened: false,
        infoOpened: false,
        reviewOpened: false,
        messageSending: false,
        messagePlaceholder: "Опишите замеченные Вами недостатки, или возникшие у Вас идеи и предложения",
        version: packageJson.version,
        wins_count:0,
        ip : "",
        review: "",
        gameSettings:{},
        donaters:[
          "ÆWECANT",
          "InZeR",
          "mactwwwish",
          "ВаДяО",
          "alkah cerepaxa",
          "Soozuchi",
          "CR7",
          "RᎽmᏃ",
          "bynikkk",
          "strzhx | СТРИЖ",
          "elizaveta",
          "Миша",
          "Пумба",
          "Vetalja",
          "Orest",
          "floop",
          "Hyperb_1",
          "Kutsko_official",
          "рейден 2.0",
          "сике.пахан твой",
          "злой",
          "orangutan",
        ]
      }
    },
    methods:{
      openGameSettings(){
        this.openSettings = true;
      },
      closeSettings(){
        this.openSettings = false;
      },
      setGameSettings(cards_in_hand, use_bonus, cards_in_game, game_id,use_sound,remember,splav_strongest){
        this.gameSettings.cards_in_hand = cards_in_hand;
        this.gameSettings.use_bonus = use_bonus;
        this.gameSettings.cards_in_game = cards_in_game;
        this.gameSettings.gameId = game_id;
        this.gameSettings.use_sound = use_sound;
        this.gameSettings.remember = remember;
        this.gameSettings.splav = splav_strongest;
        if(remember){
          localStorage.setItem('gameData', cards_in_hand+";"+use_bonus+";"+cards_in_game+";"+game_id+";"+use_sound+";"+remember+";"+splav_strongest);
        } else {
          localStorage.setItem('gameData', '')
        }
        this.gameWindowOpened = true;
        let footer = document.querySelector('footer');
        footer.style.display = 'none'
        let version = document.querySelector('.version');
        version.style.display = 'none'
      },
      gaveOver(){
        this.gameWindowOpened = false;
        this.openSettings = false;
        let footer = document.querySelector('footer');
        footer.style.display = 'flex'
        let version = document.querySelector('.version');
        version.style.display = 'block'
      },
      winsCount(num){
        this.wins_count = num;
      },
      saveIp(name){
        this.ip = name;
      },
      sendReview(){
        try{
           fetch('https://checkip.amazonaws.com/')
          .then((res) => res.text())
          .then((data) => this.saveIp(data))
        } catch (e){
          console.log(e);
        } 
        if(this.review.trim()!=""){
          this.messageSending = true;
          let lever = true;
          let trys = localStorage.getItem('trys');
          if(trys){
            if(Number(trys)<=2){
              localStorage.setItem('trys',Number(trys)+1);
            } else {
              lever = false;
              let rewtimer = localStorage.getItem('rewtimer');
              if(rewtimer){
                var d1 = new Date(); 
                var d2 = new Date(rewtimer); 
                var diff = Math.abs(d2-d1);
                if(diff/86400000 > 1){
                  lever = true;
                  localStorage.setItem('trys','1');
                  localStorage.setItem('rewtimer','');
                }
              } else {
                localStorage.setItem('rewtimer', new Date());
              }
            }
          } else {
            localStorage.setItem('trys','1');
          }
          if(lever){
            setTimeout(()=>{
              db.collection("reviews").add({
                  id : Date.now(),
                  ip : this.ip,
                review: this.review,
                date: firebase.firestore.FieldValue.serverTimestamp(),
                platform: navigator.platform,
              })
              this.review = "";
              this.messageSending = false;
              this.messagePlaceholder = "Ваш отзыв был доставлен. Спасибо, что помогаете нам становиться лучше!"
            },2000);
          } else {
            this.review = "";
            this.messageSending = false;
            this.messagePlaceholder = "Видимо, нам есть над чем работать) Больше сообщений отправлять нельзя"
          }
          } else {
              this.messagePlaceholder = "Опишите замеченные Вами недостатки, или возникшие у Вас идеи и предложения"
        }
      }
    },
    mounted(){
      let wins = localStorage.getItem('winsCount');
        if(wins!=''){
            let winCount = Number(wins);
            this.wins_count = winCount;
        }
      let gamedata = localStorage.getItem('gameData');
      if(gamedata!=''){
        let settings_arr = gamedata.split(';');
        this.gameSettings.cards_in_hand = settings_arr[0]
        this.gameSettings.use_bonus = JSON.parse(settings_arr[1].toLowerCase());
        this.gameSettings.cards_in_game = settings_arr[2]
        this.gameSettings.gameId = settings_arr[3]
        this.gameSettings.use_sound = JSON.parse(settings_arr[4].toLowerCase());
        this.gameSettings.remember = settings_arr[5]
        this.gameSettings.splav = JSON.parse(settings_arr[6].toLowerCase());
      }
    }
  }
  </script>
  <style>
.main_wrapper{
    background-image: url('../assets/images/menu.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
}
.info{
  position: absolute;
  width: 400px;
  height: 60vh;
  background: rgba(0,0,0,0.9);
  color: #fff;
  padding: 15px;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  overflow: visible;
  overflow-y: auto;
  animation: rideleft 300ms linear;
  transform: translate(-90%, -115%);
}
.review{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 220px;
}
.review textarea{
  height: 80%;
  background: rgba(0,0,0,0);
  color: #fff;
  border: #c0c0c0;
  outline-width: 0;
  resize: none;
}
.review button{
  height: 19%;
  margin-top: 1%;
  background: 0;
  border: 2px solid #c0c0c0;
  color: #fff;
}
.review button:hover{
  background: #444444;
}
.review span{
  position: absolute;
  left: 50%;
  top: 50%;
  color: #666;
  display: inline-block;
  transform: translate(-50%,-50%);
}
@keyframes rideleft {
  0%{
    transform: translate(10%, -115%);
    opacity: 0
  };
  100%{
    transform: translate(-90%, -115%);
    opacity: 1
  };
}
.info::-webkit-scrollbar {
  width: 12px;               /* ширина scrollbar */
}
.info::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.9);       /* цвет дорожки */
}
.info::-webkit-scrollbar-thumb {
  background-color: #cad52a;    /* цвет плашки */
  border-radius: 20px;       /* закругления плашки */
  border: 3px solid rgba(0,0,0,0.9);  /* padding вокруг плашки */
}
.info a{
  color:#cad52a;
}
.info div{ position: relative;padding-bottom: 20px; }
.info div img{
  width: 100px;
  padding: 0 10px;
}
.info div span{
  position: absolute;
  right: 5px;
  bottom:0px;
  opacity: 0.5;
  color:#fff;
  font-size: 12px;
}
@media(max-width: 500px){
  html{
    overflow: hidden;
  }
  #app{
    height: 100%;
  }
  body{
    height: 100%;
  }
  .main_wrapper{
    height: 100% !important;
    background-position: 80% !important;
    overflow: hidden !important;
  }
  .version{
    transform: translate(5px, -70px);
  }
  .social{
    transform: translate(5px, -70px);
  }
  header{
    width: 100%;
    padding: 5px;
  }
  header nav a{
    padding: 0 5px;
    font-size: 20px;
    height: 35px;
  }
  header nav{
    justify-content: space-around;
  }
  footer{
    height: 40px;
    padding: 10px;
  }
  .game_version,
  .game_settings{
    width: 75%;
    height: 55%;
    z-index: 1;
  }
  .close_settings{
    top: 10px;
    right: 10px;
  }
  .game_setting_wrapper{
    width: 100%;
  }
  .game_setting_wrapper span{
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrap{
    justify-content: space-between;
  }
  .game_donaters,
  .game_rules{
    width: 70%;
    height: 60%;
  }
  .rules_text{
    font-size: 19px;
  }
  .active_state{
    font-size: 23px;
    top: 32%;
  }
  .collecter_result {
    padding-top: 15px;
  }
  .donaters_text p,
  .collecter_result p{
    font-size: 16px;
  }
  .version_text h3{
    padding-bottom: 15px;
  }
  .version_text ul{
    font-size: 20px;
  }
  .version_text li{
    padding-bottom: 5px;
  }
  .social .link{
    width: 28px;
    margin: 1px;
  }
  .social_link:last-child{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  input[type="range"]{
    width: 50%;
  }
  .info:after{
    display: none;
  }
  .info{
    width: 360px;
    animation-name: infoleft;
    transform: translate(-90%, -112%);
  }
  @keyframes infoleft {
    0%{ transform: translate(10%, -112%); opacity: 0 };
    100%{ transform: translate(-90%, -112%); opacity: 1 };
  }
  .info div img{
    padding: 0 15px;
  }
  @keyframes rideleft {
    0%{ transform: translate(10%, -15%); opacity: 0 };
    100%{ transform: translate(-90%, -15%); opacity: 1 };
  }
  .review{
    width: 100%;
    min-width: 220px;
    transform: translate(0%,-15%);
    animation-name: rideleft;
  }
}
</style>
<template>
  <div class="game_zone">
    <LoadingScreen :loading="{zoneLoad,cardsLeftLoad}" :game="this.gameId" />
    <img src="../assets/fon.jpg" @load="this.zoneLoad=false" class="game_fon">
      <div class="card_holder">
        <div class="bot_cards">
          <PlayerCard 
          class="player_card_info" 
          :nickname="this.botName"
            :cardsCollect="this.botCollected"
            :adding="this.botAdding"
          />
          <div v-if="this.botCollected" class="bot_collected_deck"></div>
          <Card 
            v-for="card in botCards"
            :key="card.number"
            :card="card"
            class="card"
            :cantakecard="this.canTakeCard"
            @takeThisCard="takeThisCard"
          /> 
          <div v-if="this.botGivesCard" class="bot_give_card">{{ this.botName }} отдает карту</div>
        </div>
        <div class="play_zone">
          <div class="load_wrap">
            <Card 
            v-for="card in botCards"
            :key="card.number"
            :card="card"
            class="card"
            v-clock
            /> 
            <Card 
            v-for="card in this.cardNumbers"
            :key="card"
            :card="this.cards.find((x)=>x.number==card)"
            class="card"
            v-clock
            /> 
          </div>
          <div 
          v-if="this.cardNumbers.length"
          class="cards_left">
            <span><b>Карт осталось:</b> {{ this.cardNumbers.length }}</span>  
            <img @load="this.cardsLeftLoad = fasle" v-if="this.gameSettings.gameId==1" src="../assets/images/manyleft.png" alt="">
            <img @load="this.cardsLeftLoad = fasle" v-if="this.gameSettings.gameId==2" src="../assets/images/tmntmanyleft.png" alt="">
          </div>
          <div class="game_control">
            <div @click="this.exit=!this.exit" class="back"></div>
            <div @click="turnOffSound" class="sound"></div>
          </div>
          <div class="game_hint">{{ this.gameHint }}</div>
          <div 
            class="your_round bot"
            v-if="!this.WhosPlayRound"
            >Ход {{ this.botName }}</div>
          <Card 
            v-for="card in cardsInGame"
            :key="card.number"
            :card="card"
            :splav="this.gameSettings.splav"
            class="card"
          />
          <div 
            class="your_round"
            v-if="this.WhosPlayRound"
            >Ваш ход</div>
          <p 
            class="active_state">
            {{ this.currentStat.name }}:
            <span >
              {{ this.currentStat.value }}
            </span>
          </p> 
        </div>
        <div class="player_cards">
          <PlayerCard 
            class="player_card_info" 
            :nickname="Вы"
            :cardsCollect="this.playerCollected"
            :adding="this.playerAdding"
            />
            <div v-if="this.playerCollected" class="player_collected_deck"></div>
          <div v-if="this.playerControl" class="player_control">
            <button @click="playerFinishRoundEarlier">Завершить раунд</button>
          </div>
          <div v-if="this.giveupControl" class="giveup_control">
            <button @click="playerGiveUp">Сдаться</button>
          </div>
          <div class="player_cards_wrapper">
            <Card 
            v-for="card in playerCards"
            :key="card.number"
            :card="card"
            class="card"
            :gametype="this.gameSettings.gameId"
            :strongest="this.playerStrongestCard"
            :splav="this.gameSettings.splav"
            @stealCard="stealCard"
            @saveCard="saveCard"
            @getStat="setStat"
            @playerGiveCard="playerGiveCard"
            @click="selectCard"
            :playergives="this.playerGivesCard"
            :current="this.currentStat"
            :whoplaysround="this.WhosPlayRound"
            :cansave="this.canSave"
            />     
          </div>
        </div>
      </div>
    </div>
    <div 
    v-if="this.resultWindow"
    class="game_result">
      <h2 v-if="this.playerWins">Вы победили!</h2>
      <h2 v-if="this.botWins">Победил(а) {{ this.botName }}</h2>
      <h2 v-if="this.resultEqual">Ничья</h2>
      <div class="collecter_result">
        <p>Игрок собрал карт: {{ this.playerCollected }}</p>
        <p>{{ this.botName }} собрал(а) карт: {{ this.botCollected }}</p>
      </div>
      <a class="close_results"
        @click="closeResult">+</a>    
    </div>
    <div v-if="this.exit" class="exit_menu">
      <a class="close_exit"
        @click="this.exit=false">+</a>  
      <span>Хотите выйти?</span>
      <div class="exit_answers">
        <span @click="closeResult">Да</span>
        <span @click="this.exit=false">Нет</span>
      </div>
    </div>
</template>
<script>
import Card from './Card.vue'
import PlayerCard from './PlayerCard.vue'
import LoadingScreen from './LoadingScreen.vue'
export default {
  name: 'GameZone',
  components: {
    Card,
    PlayerCard,
    LoadingScreen
  },
  data(){
    return{
      cards: [],
      cardNumbers: [],
      playerCards: [],
      botCards: [],
      WhosPlayRound: true,
      playerStrongestCard: {},
      botStrongestCard: {},
      Selected: false,
      SelectedCard: {},
      cardsInGame: [],
      currentStat: {},
      playerCollected: 0,
      botCollected: 0,
      canTakeCard: false,
      playerGivesCard: false,
      botGivesCard: false,
      playerCanGiveUp: false,
      noMoreCards: false,
      playerWins:false,
      botWins:false,
      resultEqual:false,
      resultWindow: false,
      playerControl: false,
      giveupControl: false,
      zoneLoad: true,
      cardsLeftLoad: true,
      canSave: false,
      botName: "",
      gameHint: "",
      soundOn: true,
      selectcardsound : new Audio(require('../assets/sound/selectcard.mp3')),
      dropontable : new Audio(require('../assets/sound/dropontable1.mp3')),
      collectcards : new Audio(require('../assets/sound/collectcards.mp3')),
      exit: false,
      selectorLocker: true,
      playerAdding: 0,
      botAdding: 0
    }
  },
  props:{
    gameSettings:{
      type: Object
    },
    botNames:{
      type: Array
    },
  },
  mounted(){
    if(!this.gameSettings.use_sound){
      console.log('звук выключен')
      this.selectcardsound = null;
      this.dropontable = null;
      this.collectcards = null;
      let sound = document.querySelector('.sound');
      sound.style.backgroundImage = "url('" + require('../assets/images/button-muter.png') + "')"
    }
    if(this.collectcards){
      this.collectcards.play();
    }
    if(this.gameSettings.gameId==1){
      this.cards = this.$store.state.spiderCards;
      let game_zone = document.querySelector('.game_zone');
      game_zone.classList.add('spider');
      if(this.gameSettings.splav){
        for(let i=0;i<this.cards.length;i++){
          if(this.cards[i].number==142){
            this.cards[i].stats=[1000,1000,1000,1000,1000]
            break;
          }
        }
      }
    }
    if(this.gameSettings.gameId==2){
      this.cards = this.$store.state.turtlesCards;
      let game_zone = document.querySelector('.game_zone');
      game_zone.classList.add('turtles');
    }
    let name_id = Math.floor(Math.random() * (this.botNames.length - 1 + 1) + 0);
    this.botName = this.botNames[name_id];
    let allNumbs = [];
    let randomUsed = [];
    for(let i=0;i<this.gameSettings.cards_in_game;i++){
      let rand = Math.floor(Math.random() * (this.cards.length - 1 + 1) + 0)
      let el = this.cards[rand].number;
      if(el){
        if(randomUsed.indexOf(rand)==-1){
          allNumbs.push(el);
          randomUsed.push(rand); 
        } else {
          i--
        }
      }
    }
    let limit = this.gameSettings.cards_in_hand;
    let playerNumbers = [];
    while(playerNumbers.length<limit){                      //min    //min 
      let rand = Math.floor(Math.random() * (allNumbs.length - 2 + 1) + 0)
      if(playerNumbers.indexOf(allNumbs[rand]) == -1){
        playerNumbers.push(allNumbs[rand]);
        allNumbs.splice(rand, 1);
      }
    }
    let botNumbers = [];
    while(botNumbers.length<limit){                               //min    //min 
      let rand = Math.floor(Math.random() * (allNumbs.length - 2 + 1) + 0)
      if(botNumbers.indexOf(allNumbs[rand]) == -1){
        botNumbers.push(allNumbs[rand]);
        allNumbs.splice(rand, 1);
      }
    }
    let playerCards = []
    for(let i=0;i<playerNumbers.length; i++){
        for(let j=0;j<this.cards.length;j++){
          if(playerNumbers[i]==this.cards[j].number){
            playerCards.push(this.cards[j]);
            break;
          }
        }
      }
    this.playerCards = playerCards;
    let botCards = [];
    for(let i=0;i<botNumbers.length; i++){
        for(let j=0;j<this.cards.length;j++){
          if(botNumbers[i]==this.cards[j].number){
            botCards.push(this.cards[j]);
            break;
          }
      }
    }
    this.botCards = botCards;
    this.cardNumbers=allNumbs;           //2 для рандома
    let whos = Math.floor(Math.random() * (2 - 1 + 1) + 1);
    this.getBotStrongest();
    this.getPlayerStrongest();
    if(whos > 1){
      this.WhosPlayRound = false;
    } else {
      this.WhosPlayRound = true;
    }
  },
    methods:{
      zoneLoading(){
        this.zoneLoad = false;
        console.log(this.zoneLoad);
      },
      setStat(value, card){ this.currentStat = value; this.SelectedCard = card; this.setPlayerCardInGame(); },
      setPlayerCardInGame(){
        let updatedCards = this.updateUserCards(this.playerCards, this.SelectedCard);
        this.playerCards = updatedCards;
        let arr = this.cardsInGame
        arr.push(this.SelectedCard);
        this.cardsInGame = arr;
        this.WhosPlayRound = false;
        if(this.dropontable){
          this.dropontable.play();
        }
      },
      getBotStrongest(){
        let results = [];
        let allMaxes = [];
        for(let i=0;i<this.botCards.length;i++){
          let card = this.botCards[i];
          let max = Math.max.apply(null, card.stats);
          allMaxes.push(max);
          for(let i=0;i<card.stats.length;i++){
            if(card.stats[i] == max){ results.push({value: card.stats[i], card:card}) }
          }
        }
        let strongest = Math.max.apply(null, allMaxes);
        for(let i=0;i<results.length;i++){   
          if(results[i].value==strongest){
            this.botStrongestCard = results[i].card; 
          } 
        }
      },
      getPlayerStrongest(){
      let results = [];
      let allMaxes = [];
        for(let i=0;i<this.playerCards.length;i++){
          let card = this.playerCards[i];
          let max = Math.max.apply(null, card.stats);
          allMaxes.push(max);
          for(let i=0;i<card.stats.length;i++){
            if(card.stats[i] == max){ results.push({value: card.stats[i], card:card}) }
          }
        }
        let strongest = Math.max.apply(null, allMaxes);
        for(let i=0;i<results.length;i++){   
          if(results[i].value==strongest){
            this.playerStrongestCard = results[i].card; 
          } 
        }
      },
      updateUserCards(userCards,card){
        let updateCards = [];
        for(let i=0;i<userCards.length;i++){
          if(userCards[i]!=card){
            updateCards.push(userCards[i])
          }
        }
        return updateCards;
      },
      selectCard(event){
          if(this.WhosPlayRound){
            if(this.selectorLocker){
              let selected = document.querySelector('.selected');
              if(selected == event.target){
                event.target.classList.remove('selected');
              } else {
                if(selected){
                  selected.classList.remove('selected');
                }
                event.target.classList.toggle('selected');
              }
              this.Selected = true;
            }
          }
      },
      botSelectHisCard(i,stat){
        let newBots = [];
        for(let j=0;j<this.botCards.length;j++){
            if(this.botCards[j]!=this.botCards[i]){ newBots.push(this.botCards[j]); } 
            else { 
            this.cardsInGame.push(this.botCards[j]); 
          }
        }
        this.currentStat.value = this.botCards[i].stats[stat]; 
        this.botCards=newBots;
        if(this.dropontable){
        this.dropontable.play();
        }
      },
      fullPlayerCards(){
        let newCards = []; 
          for(let i=0;i<this.playerCards.length;i++){
            newCards.push(this.playerCards[i].number);
          }
          let limit = this.gameSettings.cards_in_hand;
          let allNumbs = this.cardNumbers;
          if(allNumbs.length>1){
            while(newCards.length<limit){
              let rand = Math.floor(Math.random() * (allNumbs.length - 2 + 1) + 0)
              if(newCards.indexOf(allNumbs[rand]) == -1){
                newCards.push(allNumbs[rand])
                allNumbs.splice(rand,1);
              }
            }
          } 
          if(allNumbs.length==1){
            if(newCards.length<limit){
              newCards.push(allNumbs[0])
              this.cardNumbers = []
              this.noMoreCards = true
              // создать модель результов игры
            }
          }
          if(allNumbs.length==0){
              this.noMoreCards = true
            }
          let newPlayerCards = []
          for(let i=0;i<newCards.length; i++){
            for(let j=0;j<this.cards.length;j++){
              if(newCards[i]==this.cards[j].number){
                newPlayerCards.push(this.cards[j]);
                break;
              }
            }
          }
          this.playerCards = newPlayerCards;
          if(this.noMoreCards && this.playerCards.length==0){
            console.log('игра завершена');
            this.gameEnded();
          }
      },
      fullBotCards(){
        let newCards = [];
            for(let i=0;i<this.botCards.length;i++){
              newCards.push(this.botCards[i].number);
            }
            let limit = this.gameSettings.cards_in_hand;
            let allNumbs = this.cardNumbers;
            if(allNumbs.length>1){
              while(newCards.length<limit){
                let rand = Math.floor(Math.random() * (allNumbs.length - 2 + 1) + 0)
                if(newCards.indexOf(allNumbs[rand]) == -1){
                  newCards.push(allNumbs[rand])
                  allNumbs.splice(rand,1);
                }
              }
            }
            if(allNumbs.length==1){
              if(newCards.length<limit){
               newCards.push(allNumbs[0])
               this.cardNumbers = []
               this.noMoreCards = true
               console.log('No more cards')
              }
            }
            if(allNumbs.length==0){
              this.noMoreCards = true
            }
            let newBotCards = []
            for(let i=0;i<newCards.length; i++){
              for(let j=0;j<this.cards.length;j++){
                if(newCards[i]==this.cards[j].number){
                  newBotCards.push(this.cards[j]);
                  break;
                }
              }
            }
            this.botCards = newBotCards;
            if(this.noMoreCards && this.botCards.length==0){
              console.log('игра завершена')
              this.gameEnded();
            }
      },
      fullCards(){
        this.getPlayerStrongest()
        if(this.WhosPlayRound){
          this.fullBotCards()
          this.fullPlayerCards()
        } else {
          this.fullPlayerCards()
          this.fullBotCards()
        } 
      }, 
      stealCard(card){
        if(this.WhosPlayRound){
            let value = card.steal;
            value>0?value:value=value*-1;
            console.log(value);
            if(this.cardsInGame.length!=0){
              this.gameHint = "Карта используется первой в раунде";
            } else { 
              if(this.botCollected>=value){
                this.cardsInGame.push(card);
                let updatedCards = this.updateUserCards(this.playerCards, card);
                this.playerCards = updatedCards;
            //                              сумму всех карт от игроков
            this.gameHint = "Игрок забирает карт: " + value;
            this.botCollected = this.botCollected-value;
            this.playerAdding = value+1;
            this.playerCollected +=value
            this.botAdding = -value;
            setTimeout(()=>{
              this.cardsInGame = []
              this.fullCards();
              if(this.collectcards){
                this.collectcards.play();
              }
              this.WhosPlayRound = true;
            },2099);
            this.getPlayerStrongest();
          } else {
            this.gameHint = "У " + this.botName + " нет стольких карт";
            }
            }
        }
      },
      saveCard(card){
        if(this.canSave){
          let updatedCards = this.updateUserCards(this.playerCards, card);
          this.playerCards = updatedCards;
          this.cardsInGame.push(card);
          this.canSave = false;
          this.playersRound();
          this.playerControl = false;
          this.getPlayerStrongest();
        } else {
          this.gameHint = "Карта используется при поражении";
        }
      },
      playersRound(){
        this.gameHint = "Игрок выигрывает раунд";
        setTimeout(()=>{
          this.playerCollected +=this.cardsInGame.length;
          this.playerAdding = this.cardsInGame.length;
          this.cardsInGame = []
          this.currentStat = {}
          this.fullCards();
          if(this.collectcards){
            this.collectcards.play();
          }
          this.WhosPlayRound = true;
          // }
        },2099);
      },
      takeThisCard(card){
        if(this.canTakeCard){
          this.canTakeCard = false;
          let cardsInGame = this.cardsInGame;
          let newBotCards = []
          for(let i=0;i<this.botCards.length;i++){
            if(this.botCards[i]==card){
              cardsInGame.push(this.botCards[i])
            } else {
              newBotCards.push(this.botCards[i]);
            }
          }
          this.cardsInGame = cardsInGame;
          this.botCards = newBotCards;
          this.botGivesCard = false;
          let bot_collected_deck = document.querySelector('.bot_cards');
          bot_collected_deck.classList.remove('given');
          if(this.dropontable){
          this.dropontable.play();
          }
          setTimeout(()=>{
            this.playerCollected +=this.cardsInGame.length;
            this.playerAdding = this.cardsInGame.length;
            this.cardsInGame = []
            this.currentStat = {}
            this.fullCards();
            if(this.collectcards){
              this.collectcards.play();
            }
            this.WhosPlayRound = true;
          },2099)
          this.getPlayerStrongest();
          this.getBotStrongest();
        }
      },
      playerGiveCard(card){
        if(this.playerGivesCard){
          this.playerGivesCard = false;
          let cardsInGame = this.cardsInGame;
          let newPlayerCards = [];
          for(let i=0;i<this.playerCards.length;i++){
            if(this.playerCards[i]==card){
              cardsInGame.push(this.playerCards[i])
            } else {
              newPlayerCards.push(this.playerCards[i])
            }
          }
          this.cardsInGame = cardsInGame;
          this.playerCards = newPlayerCards;
          this.getPlayerStrongest();
          this.selectorLocker = false;
          setTimeout(()=>{
            this.botCollected +=this.cardsInGame.length;
            this.botAdding = this.cardsInGame.length;
            this.cardsInGame = []
            this.currentStat = {}
            this.fullCards();
            let playerCards = document.querySelectorAll('.player_cards .card');
            for(let i=0;i<playerCards.length;i++){
              playerCards[i].classList.remove('give');
            }
            this.selectorLocker = true;
            if(this.collectcards){
              this.collectcards.play();
            }
            this.playerGivesCard = false;
            this.WhosPlayRound = false;
            this.getPlayerStrongest();
          },2099)
          let selected = document.querySelector('.selected');
          if(selected){selected.classList.remove('selected');}
        }
      },
      botSteal(){
        for(let i=0;i<this.botCards.length;i++){
          let card = this.botCards[i];
          if(card.steal){
            let value = 0;
            card.steal>0?value=card.steal:value=card.steal*-1;
            if(this.playerCollected>=value){
              this.cardsInGame.push(card);
              let updatedCards = this.updateUserCards(this.botCards, card);
              this.botCards = updatedCards;
              //                              сумму всех карт от игроков
              this.gameHint = this.botName +" забирает карт: " + value;
              this.playerCollected = this.playerCollected-value;
              this.botAdding = value+1;
              this.botCollected +=value
              this.playerAdding = -value;
              setTimeout(()=>{
                this.cardsInGame = []
                this.fullCards();
                if(this.collectcards){
                  this.collectcards.play();
                }
                this.botPlays();
              },2099);
              this.getPlayerStrongest();
            }
          }
        }
      },
      botPlays(){
        setTimeout(()=>{
          //if(this.botCollected !=0 || this.playerCollected != 0){
            let has_steal = false;
            for(let i=0;i<this.botCards.length;i++){
              let card = this.botCards[i];
              if(card.steal){
                let value = 0;
                card.steal>0?value=card.steal:value=card.steal*-1;
                if(this.playerCollected>=value){
                  this.botSteal();
                  has_steal = true;
                }
              }
            }
            if(!has_steal){
              this.getBotStrongest();
              let maxStat = Math.max.apply(null, this.botStrongestCard.stats);
            if(this.gameSettings.gameId==1){
              for(let i=0;i<5;i++){
                if(maxStat==this.botStrongestCard.stats[i]){
                  if(i==0){ this.currentStat.name = "Интеллект";this.currentStat.id=0 }
                  if(i==1){ this.currentStat.name = "Сила";this.currentStat.id=1 }
                  if(i==2){ this.currentStat.name = "Скорость и Ловкость";this.currentStat.id=2 }
                  if(i==3){ this.currentStat.name = "Особые умения";this.currentStat.id=3 }
                  if(i==4){ this.currentStat.name = "Бойцовские навыки";this.currentStat.id=4 }
                  // this.currentStat.value = maxStat;
                  break;
                }
              }
            }
            if(this.gameSettings.gameId==2){
              for(let i=0;i<4;i++){
                if(maxStat==this.botStrongestCard.stats[i]){
                  if(i==0){ this.currentStat.name = "Сила";this.currentStat.id=0 }
                  if(i==1){ this.currentStat.name = "Ловкость";this.currentStat.id=1 }
                  if(i==2){ this.currentStat.name = "Мастерство";this.currentStat.id=2 }
                  if(i==3){ this.currentStat.name = "Смекалка";this.currentStat.id=3 }
                  // this.currentStat.value = maxStat;
                  break;
                }
              }
            }
            let mvosstat = 10000;
            // if(this.gameSettings.gameId==2){
            //   mvosstat = 10000;
            // }
            for(let i=0;i<this.botCards.length;i++){
              if(this.botCards[i].stats[this.currentStat.id] < mvosstat && this.botCards[i].type!="bonus"){
                mvosstat = this.botCards[i].stats[this.currentStat.id];
              }
            }
            let selectedBotCard = {}
            for(let i=0;i<this.botCards.length;i++){
              if(this.botCards[i].stats[this.currentStat.id] == mvosstat ){
                selectedBotCard = this.botCards[i];
                let arr = []
                arr.push(this.botCards[i]);
                this.cardsInGame = arr
                this.currentStat.value = this.botCards[i].stats[this.currentStat.id];
                break;
              }
            }
            let newBotCards = []
            for(let i=0;i<this.botCards.length;i++){
              if(this.botCards[i]!=selectedBotCard){
                newBotCards.push(this.botCards[i]);
              }
            }
            this.botCards = newBotCards;
            this.WhosPlayRound = true; 
            if(this.dropontable){
              this.dropontable.play();
            }
          }
        },2099);
        this.getPlayerStrongest();
      },
      botGiveUp(){
        this.gameHint = this.botName + " отдает одну карту";
        let bot_collected_deck = document.querySelector('.bot_cards');
        bot_collected_deck.classList.add('given');
        this.canTakeCard = true;
      },
      playerGiveUp(){
        if(this.playerCanGiveUp){
          this.playerCanGiveUp = false;
          let player_cards = document.querySelectorAll('.player_cards .card');
          for(let i=0;i<player_cards.length;i++){
            player_cards[i].classList.add('give');
          }
          this.playerGivesCard = true;
          this.giveupControl = false;
        }
      },
      playerFinishRoundEarlier(){
          this.canSave = false;
          this.botCollected += this.cardsInGame.length;
          this.botAdding = this.cardsInGame.length;
          this.cardsInGame = []
          this.fullCards();
          this.playerControl = false;
          this.currentStat = {}
          if(this.collectcards){
            this.collectcards.play();
          }
          this.WhosPlayRound = false;
          let selected = document.querySelector('.selected');
          if(selected){selected.classList.remove('selected');}

      },
      gameEnded(){
        this.playerCollected += this.playerCards.length;
        this.playerCards = []
        this.botCollected += this.botCards.length
        this.botCards = []
        if(this.playerCollected > this.botCollected){
          if(this.resultWindow!=true){
            let wins = localStorage.getItem('winsCount');
            if(Number(wins)>0){
              let wins_count = Number(wins);
              wins_count=wins_count+1;
              localStorage.setItem('winsCount', wins_count);
            } else {
              localStorage.setItem('winsCount', 1);
            }
            wins = localStorage.getItem('winsCount');
            console.log('записаный итог: ' + wins);
            this.$emit('winsCount', Number(wins));
          }
          this.playerWins = true
          this.botWins = false
          this.resultEqual = false
          this.WhosPlayRound = true;
        } 
        this.resultWindow = true;
        if(this.playerCollected < this.botCollected){
          this.botWins = true
          this.playerWinsWins = false
          this.resultEqual = false
          this.WhosPlayRound = false;
        } 
        if(this.playerCollected == this.botCollected){
          this.botWins = false
          this.playerWinsWins = false
          this.resultEqual = true
        } 
      },
      closeResult(){
        this.botCard = []
        this.playerCards = []
        this.cardNumbers = []
        console.log('игра завершена')
        this.$emit('gameOver', false);
      },
      turnOffSound(){
        this.soundOn = !this.soundOn
        if(this.soundOn){
          this.selectcardsound = new Audio(require('../assets/sound/selectcard.mp3'))
          this.dropontable = new Audio(require('../assets/sound/dropontable1.mp3'))
          this.collectcards = new Audio(require('../assets/sound/collectcards.mp3'))
          let sound = document.querySelector('.sound');
          sound.style.backgroundImage = "url('" + require('../assets/images/sound.png') + "')"
        } else {

          this.selectcardsound = null;
          this.dropontable = null;
          this.collectcards = null;
          let sound = document.querySelector('.sound');
          sound.style.backgroundImage = "url('" + require('../assets/images/button-muter.png') + "')"
        }
      },
      updateAdding(str){
        if(this.playerAdding!=0 || this.botAdding!=0 ){
              let player_add = document.querySelector(str);
              if(player_add){
                player_add.classList.add('hidden');
                setTimeout(()=>{
                  this.playerAdding = 0;
                  this.botAdding = 0;
                  player_add.classList.remove('hidden');
                },1999)
              }
            }
      },
      checkForAllBonus(userCards,userCollected){
        let all_bonus = 0;
        let lever = false;
        for(let i=0;i<userCards.length;i++){
          let card = userCards[i];
          if(card.steal || card.save){
            let value = 0;
            card.steal>0?value=card.steal:value=card.steal*-1;
            value<=userCollected?lever=true:lever=false;
            all_bonus++;
          }
        }
        return { bonuses:all_bonus, lever:lever }
      }
    },
      watch:{
        WhosPlayRound:{
          immediate: true,
          handler(){
            if(this.WhosPlayRound){
              console.log('Ходит игрок');
              if(this.cardsInGame.length==0){
                let result = this.checkForAllBonus(this.playerCards, this.botCollected);
                console.log(result);
                console.log(this.playerCards.length);
                if(this.playerCards.length!=0){
                  if(result.bonuses==this.playerCards.length){
                    if(!result.lever){ 
                      this.gameHint = "Нет используемых карт"
                    this.playerCanGiveUp = true;
                      this.giveupControl = true;
                    }
                  } 
                }
              }
              if(this.currentStat.name){
                let canDefit = false;
                for(let i=0;i<this.playerCards.length;i++){
                  if(this.playerCards[i].stats[this.currentStat.id]>this.currentStat.value){ canDefit = true }  
                } 
                if(!canDefit){
                  if(this.cardsInGame.length>=2){ 
                    //срабатывает когда победить эту карту не получится
                    //console.log('случай 1');
                    let canUseSave = false;
                    for(let i=0;i<this.playerCards.length;i++){
                      if(this.playerCards[i].save){ canUseSave = true;break; }  
                    } 
                    if(canUseSave){
                      this.canSave = true;
                      this.playerControl = true;
                      this.gameHint = this.botName+" лидирует";
                    } else {
                      this.gameHint = this.botName+" выигрывает раунд";
                      this.selected = false;
                      this.SelectedCard = {};
                      this.selectorLocker = false;
                      setTimeout(()=>{
                        this.selectorLocker = true;
                        this.botCollected +=this.cardsInGame.length;
                        this.botAdding =this.cardsInGame.length;
                        this.cardsInGame = []
                        this.currentStat = {}
                        this.fullCards();
                        this.WhosPlayRound = false;
                        if(this.collectcards){
                          this.collectcards.play();
                        }
                        this.getPlayerStrongest();
                      },2099)
                    }
                  } else {
                    if(this.cardsInGame.length<=1){
                      // срабатывает, когда победить первую карту не получится. нужно отдать одну
                      // console.log('случай 2');
                      this.gameHint = "Отдайте одну карту";
                      let player_cards = document.querySelectorAll('.player_cards .card');
                      for(let i=0;i<player_cards.length;i++){
                        player_cards[i].classList.add('give');
                      }
                      this.playerGivesCard = true;
                    } 
                  }
                } else {
                  this.playerCanGiveUp = true;
                  // срабатывает когда карту можно победить
                  // console.log('случай 3');
                  if(this.cardsInGame.length>1){
                    this.playerControl = true;
                  } else {
                    // console.log('кнопка сдаться появилась')
                    this.giveupControl = true;
                    this.playerCanGiveUp = true;
                  }
                }
              }
            } else {
              console.log('Ходит бот');
              this.playerControl = false;
              this.giveupControl = false;
              this.playerCanGiveUp = false;
              if(this.currentStat.name){
                let lever = false; 
                for(let i=0;i<this.botCards.length;i++){
                  if(this.botCards[i].stats[this.currentStat.id]>this.currentStat.value){
                    lever = true;
                    setTimeout(()=>{
                      this.botSelectHisCard(i,this.currentStat.id);
                      if(this.dropontable){
                        this.dropontable.play();
                      }
                      //console.log('случай 4');
                      this.WhosPlayRound = true;
                    },2099);
                    break;
                  }
                }
                if(!lever){
                  if(this.cardsInGame.length>=2){
                    //срабатывает, когда бот проиграл раунд
                    //console.log('случай 5');
                    let canUseSave = false;
                    let saveCard = {};
                    for(let i=0;i<this.botCards.length;i++){
                      if(this.botCards[i].save){ canUseSave = true;saveCard = this.botCards[i];break; }  
                    }
                    if(canUseSave){
                      setTimeout(()=>{
                      let updatedCards = this.updateUserCards(this.botCards, saveCard);
                      this.botCards = updatedCards;
                      this.cardsInGame.push(saveCard);
                      this.gameHint = this.botName+" выигрывает раунд";
                      this.selected = false;
                      this.SelectedCard = {};
                      this.selectorLocker = false;
                    },1999)
                      setTimeout(()=>{
                        this.selectorLocker = true;
                        this.botCollected +=this.cardsInGame.length;
                        this.botAdding =this.cardsInGame.length;
                        this.cardsInGame = []
                        this.currentStat = {}
                        this.fullCards();
                        if(this.collectcards){
                          this.collectcards.play();
                        }
                        this.botPlays();
                        this.getPlayerStrongest();
                      },4099)
                    } else {
                      this.playersRound();
                    }
                  }
                  if(this.cardsInGame.length==1){
                    //срабатывает, если бот не может победить первую карту
                    //console.log('случай 6');
                    this.botGiveUp();
                    if(this.dropontable){
                      this.dropontable.play();
                    }
                  }
                }
              } else {
                //console.log('случай 7');
                if(this.cardsInGame.length==0){
                  let result = this.checkForAllBonus(this.botCards, this.playerCollected);
                  console.log(result);
                  if(result.bonuses==this.botCards.length){
                    if(result.lever){ 
                      this.botSteal();
                    } else {
                      this.botGiveUp();
                    }
                  } else {
                    // сделать, чтобы бот кидал максимальную кражу
                    this.botPlays();
                  }
                }
              }
            }
            this.getPlayerStrongest();
            this.getBotStrongest();
          }
        },
        gameHint:{
          handler(){
            if(this.gameHint!=""){
              let game_hint = document.querySelector('.game_hint');
              game_hint.classList.add('hint_hide');
              setTimeout(()=>{
                this.gameHint = "";
                game_hint.classList.remove('hint_hide')
              },1999)
            }   
          }
        },
        playerAdding:{
          immediate: true,
          handler(){ if(this.playerAdding!=0){this.updateAdding('.player_cards .player_info .adding') }}
          },
        botAdding:{
          immediate: true,
          handler(){ if(this.botAdding!=0){this.updateAdding('.bot_cards .player_info .adding') }}
        }
    }
}
</script>
<style>
  .game_zone{
    z-index: 100;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ccc;
  }
  .game_fon{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 50;
  }
  .card_holder{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
    z-index: 100;
    position: relative;
  }
  .bot_cards {
    height: 20%;
    position: relative;
    background: rgba(255,255,255,0.4);
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .play_zone {
    height: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
  }
  .player_cards {
    height: 20%;
    width: 100%;
    background: rgba(255,255,255,0.4);
    display: flex;
    justify-content: center;
    position: relative;
  }
  .player_cards_wrapper{
    display: flex;
    justify-content: center;
  }
  .player_cards .card{
    transform: translate(0,-36%);
    transition: all 0.4s;
  }
  .player_cards .card.give:hover{
    box-shadow: 0 0 15px 5px #b0301c !important;
  }
  .player_cards .card:not(.selected):hover{
    transition: all 0.4s;
    transform: scale(1.1) translate(0,-44%);
  }
  .load_wrap{
  opacity:0;position:absolute;display: flex;flex-wrap:wrap;  
  }
  .load_wrap .card{
    width:20px;
    height:20px;
  }
  /* .player_cards .card.selected:hover{
    transition: all 0.4s;
    transform: scale(1.2) translate(0,-100%) !important;
  } */
  .player_card_info{
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translate(0%,-50%);
    width: 25%;
    z-index: 100;
  }
  .game_zone.turtles .bot_cards .card{
    background-image: url('../assets/images/turtles cover.jpg') !important;
  }
  .bot_cards .card{
    background-image: url('../assets/images/ref.jpg') !important;
  }
  .active_state{
    position: absolute;
    right: 2%;
    font-size: 30px;
    color: #000;
    text-shadow: 2px 0 2px #fff, 
    0 2px 2px #fff, 
    -2px 0 2px #fff, 
    0 -2px 2px #fff;
    font-family: "OpinionPro";
    text-rendering: optimizeLegibility;
  }
  .active_state span{
    font-family: "OpinionPro";
  }
  .game_hint,
  .your_round{
    font-size: 40px;
    color: #fff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    position: absolute;
    right: 2%;
    top: 70%;
    font-family: "OpinionPro";
    text-rendering: optimizeLegibility;
  }
  .game_hint{
    top: 60%;
  } 
  .hint_hide{
    animation: hint_hide 2s linear;
  }
  @keyframes hint_hide{
    0%{
      transform: translate(0%,0%);
      opacity: 0;
    }
    15%{
      transform: translate(0%,-10%);
      opacity: 1;
    }
    85%{
      transform: translate(0%,-15%);
      opacity: 1;
    } 
    100%{
      transform: translate(0%,-30%);
      opacity: 0;
    }

  }
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .your_round{
      font-family: "OpinionPro";
      text-rendering: optimizeLegibility;
    }
    .active_state{
      font-family: "OpinionPro";
      text-rendering: optimizeLegibility;
    }
  }
  .collected{
    text-shadow:
      2px 0 #fff,
      0 -2px #fff,
      -2px 0 #fff,
      0 2px #fff;
      font-size: 26px;
      display: flex;
    }
    .your_round.bot{
    top: 25%;
  }
  .card.selected{
    transform: translate(0%,-90%) scale(1.1);
  }
  .bot_cards{
    position: relative;
  }
  .game_zone.turtles .bot_cards .bot_collected_deck, 
  .game_zone.turtles .player_cards .player_collected_deck{
    background-image: url('../assets/images/turtles cover.jpg');
  }
  .player_cards .player_collected_deck,
  .bot_cards .bot_collected_deck{
    position: absolute;
    width: 140px;
    height: 210px;
    left: 10%;
    top: 0;
    transform: translate(0,-40%) rotate(140deg);
    background-image: url('../assets/images/ref.jpg');
    background-size: cover;
    border: 1px solid #000;
    border-radius: 10px;
    z-index: 0;
    animation: appiarence 1000ms;
  }
  .player_cards .player_collected_deck{
    transform: translate(0,10%) rotate(50deg);
  }
  @keyframes appiarence {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  .giveup_control,
  .player_control{
    position: absolute;
    right: 2%;
    top: 50%;
    transform: translate(0,-50%);
    z-index: 10;
  }
  .cards_left{
    position: absolute;
    left: 0%;
    top: 26%;
    width: 30%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-20%,0%); 
  }
  .cards_left img{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
  }
  .cards_left span{
    text-shadow: 2px 0 #fff, 0 -2px #fff, -2px 0 #fff, 0 2px #fff;
      font-size: 26px;
      z-index: 100;
  }
  .bot_give_card{
    position: absolute;
    right: 2%;
    top: 50%;
    width: (0,-50%);
    font-size: 26px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    animation: message 400ms infinite;
  }
  @keyframes message {
    0%{
      transform: scale(1);
    }
    50%{
      transform: scale(1.2);
    }
    100%{
      transform: scale(1);
    }
  }
  .bot_cards.given .card{
    box-shadow: 0 0 15px 5px #861f0f !important;
    transition: all 0.4s;
    animation: given 1000ms infinite;
  }
  @keyframes given {
    0%{
      transform: scale(1)
    }
    50%{
      transform: scale(1.05)
    }
    100%{
      transform: scale(1)
    }
  }
  .bot_cards.given .card:hover{
    box-shadow: 0 0 15px 5px #b0301c !important;
    transition: all 0.4s;
    transform: scale(1.1) translate(0,14%);
  }
  .close_results{
    position: absolute;
      top: 20px;
      right: 20px;
      color: #fff;
      transform: rotate(45deg);
      font-size: 35px;
      text-decoration: none;
      cursor: pointer;
  }
  .game_result{
    position: absolute;
    left: 50%;
    z-index: 150;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 30%;
    background: rgba(0,0,0,0.9);
    color: #fff;
    padding: 25px;
    font-size: 26px;
    border: 8px solid #000;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .game_result h2{
    font-size: 34px;
    /* text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff; */
    color: #cad52a;
  }
  .game_control{
    position: absolute;
    left: 2%;
    top: 72%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 12%;
    height: 40px;
  }
  .back {
    width:50px;
    background-image: url('../assets/images/back.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    transition: all 0.4s;
  }
  .sound {
    width:50px;
    background-image: url('../assets/images/sound.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    transition: all 0.4s;
  }
  .back:hover,
  .sound:hover {
    transition: all 0.4s;
    transform: scale(0.95);
  }
  .exit_menu {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 120;
    width: 25%;
      height: 25%;
      background: rgba(0,0,0,0.9);
      color: #fff;
      padding: 25px;
      font-size: 26px;
      border-radius: 20px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-between;
    }
    .close_exit{
      position: absolute;
      top: 20px;
      right: 20px;
      color: #fff;
      transform: rotate(45deg);
      font-size: 35px;
      text-decoration: none;
      cursor: pointer;
    }
  .exit_answers {
    display: flex;
    justify-content: space-between;
  }
  .exit_answers span{
    cursor: pointer;
    text-decoration: underline;
  }
  .exit_answers span:hover{
    color: #cad52a;
  }
  .exit_menu span{
    text-align: center;
    font-family: "OpinionPro";
  }
  @media(max-width: 500px){
    @keyframes given {
      0%{
      transform: scale(1) translate(0,55%);
    }
    50%{
      transform: scale(1.05) translate(0,55%);
    }
    100%{
      transform: scale(1) translate(0,55%);
    }
  }
    .cards_left{
      width: 45%;
      height: 25%;
    }
    @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .your_round{
      font-family: "OpinionPro";
      text-rendering: optimizeLegibility;
      font-size: 8px;
      }
      .active_state{
      font-size: 8px;
      font-family: "OpinionPro";
      text-rendering: optimizeLegibility;
    }
  }
    .player_card_info{
      width: 97%;
    }
    .player_card img{
      width: 12%;
      border: 2px solid #cad52a;
    }
    .player_info{
      width: 80%;
    }
    .player_cards,
    .bot_cards{
      height: 10%;
    }
    .cards_left span{
      font-size: 20px;
      transform: translate(20%,-20%);
    }
    .game_hint{
      font-size: 22px;
    }
    .your_round{
      font-size: 22px;
      top:93%;
    }
    .game_zone{
      overflow: hidden;
    }
    .game_fon{
      min-width:1000px;
    }
    .player_cards .card{
      transform: translate(0,-110%);
    }
    .card.selected{
      transform: translate(0%,-120%) scale(1.1);
    }
    .bot_cards .card{
      transform: translate(0,55%);
    }
    .nickname{
      font-size: 18px;
    }
    .collected{
      font-size: 16px;
    }
    .player_cards .card:not(.selected):hover{
      transform: translate(0,-110%);
    }
    .your_round.bot{
      top:3%;
    }
    .cards_left img{
      width: 250px;
      height: 250px;
      transform: translate(-20%, 20%); 
    }
    .cards_left span{
      transform: translate(-15px,50px);
      font-size: 30px;
    }
    .cards_left b{
      display: none;
    }
    .game_zone.turtles .card_stats{
      transform: translate(-20%,15%);
    }
    .card_stats{
      font-size: 10px;
      bottom: 100%;
      transform: translate(-20%,15%);
    }
    .player_cards_wrapper .card:first-of-type .card_stats{
      transform: translate(-10%,15%);
    }
    .player_cards_wrapper .card:last-of-type .card_stats{
      transform: translate(-26%,15%);
    }
    .card_stats ul{
      width: 100%;
    }
    .card_stats li{
      padding-right: 15%;
    }
    .play_zone .card{
      position: absolute;
      top:36%;
    }
    .play_zone .card:nth-child(0){
      transform: translate(5px, 3px) rotate(7deg);
    }
    .play_zone .card:nth-child(1){
      transform: translate(2px, 6px) rotate(-5deg);
    }
    .play_zone .card:nth-child(2){
      transform: translate(7px, 3px) rotate(-6deg);
    }
    .play_zone .card:nth-child(3){
      transform: translate(4px, 4px) rotate(-10deg);
    }
    .play_zone .card:nth-child(4){
      transform: translate(2px, 8px) rotate(2deg);
    }
    .play_zone .card:nth-child(5){
      transform: translate(6px, 6px) rotate(-12deg);
    }
    .play_zone .card:nth-child(6){
      transform: translate(4px, 4px) rotate(-8deg);
    }
    .giveup_control,
    .player_control{
      top:-35%;
    }
    .card_action_button{
      bottom: 100%;
      transform: translate(0, -10px);
      width: 120px;
    }
    .card_action_button button{
      font-size: 12px;
      width: 75%;
    }
    .bot_cards .bot_collected_deck{
      width: 100px;
      height: 160px;
      left: 16%;
      transform: translate(0,-59%) rotate(120deg);
    }
    .game_result{
      width: 70%;
      height: 40%;
    }
    .game_control {
      top: 31%;
      left: 0%;
      width: 17%;
    }
    .back,
    .sound{
      width: 25px;
    }
    .exit_menu{
      width: 65%;
    }
  }
@media(max-width: 360px){
  .player_cards .card:last-of-type .card_stats{
    transform: translate(-35%,15%);
  }
}
</style>
<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'App',
  components: { },
  data(){
    return {  }
  },
  methods:{ }
}
</script>
<style>
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
html{height:100%}
body{line-height:1}
ol,ul{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}
:focus {outline: none;}
@font-face {
  font-family: "HeadingNow";
  src: url("../src/assets/fonts/Heading Now.woff") format("woff");
  src: url("../src/assets/fonts/Heading Now.woff2") format("woff2");
  src: url("../src/assets/fonts/fc8171cc0fd56e1423937690e8a96c7e.eot") format("embedded-opentype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "HeadingProTrial";
  src: url("../src/assets/fonts/Heading-Pro-Bold-Italic-trial.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "OpinionPro";
  src: url("../src/assets/fonts/OpinionProExtraCondensed-BoldItalic.woff") format("woff");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: "Mohave-BoldItalic";
  src: url("../src/assets/fonts/Mohave-BoldItalic.ttf") format("truetype");
  src: url("../src/assets/fonts/mohave_bold_italic.woff") format("woff");
  src: url("../src/assets/fonts/mohave_bold_italic.woff2") format("woff2");
  font-style: italic;
  font-weight: 700;
}
HTML CSSResult Skip Results Iframe
EDIT ON
html,
.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #cad52a;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}
.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}
.glow-on-hover:active {
    color: #cad52a;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}
.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #cad52a;
    left: 0;
    top: 0;
    border-radius: 10px;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
.wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
}
.cards_in_hand_out{
  width: 40px;
  display: flex;
  justify-content: flex-end;
}
footer{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
  padding: 3px;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
footer p{
  color: #fff;
  font-size: 11px;
}
footer span{
  text-decoration: underline;
}
header{
  position: absolute;
  right: 0;
  top: 0;
  height: 100px;
  padding: 10px;
  font-family: "HeadingProTrial";
  font-weight: 700;
}
header nav{
  display: flex;
  justify-content: flex-end;
}
header nav a{
  padding: 10px 30px;
  color: #cad52a;
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  text-shadow: 1px 0 1px #000, 
0 1px 1px #000, 
-1px 0 1px #000, 
0 -1px 1px #000;
  text-decoration: none;
  transition: all 0.3s;
}
header nav a:hover{
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s;
}
.version{
    cursor: pointer;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(5px, -30px);
    color: #fff;
    font-weight: bold;
    text-shadow: 
    0 2px 2px #000;
  }
.social{
  cursor: pointer;
  position: absolute;
    right: 10px;
    display: flex;
    flex-direction: column-reverse;
    bottom: 0;
    transform: translate(5px, -30px);
    color: #fff;
    font-weight: bold;
    justify-content: space-between;
    text-shadow: 
    0 2px 2px #000;
    z-index: 2;
  }
  .social .link{
    width: 30px;
    margin: 3px;
  }
</style>

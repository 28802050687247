<template>
    <div class="game_donaters">
        <div class="game_donaters_wrapper">
            <a class="close_donaters" @click="closeDonates">+</a> 
            <div class="donaters_text">
                <p>Огромное спасибо ребятам, которые финансово поддерживали проект:</p>
                <ul>
                    <li v-for="donater in this.donaters"
                    :key="donater">{{ donater }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>
  <script>
  export default {
    name: 'GameDonates',
    components: { },
    methods:{
        closeDonates(){
            this.$emit('closeDonates')
        }
    },
    props:{
        donaters:{
            type: Array
        },
    }
  }
  </script>
  <style>
  .game_donaters{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 75%;
    background: rgba(0,0,0,0.9);
    color: #fff;
    padding: 25px;
    font-size: 26px;
    border-radius: 20px;
    overflow-y: auto;
}
.close_donaters{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    transform: rotate(45deg);
    font-size: 35px;
    text-decoration: none;
    cursor: pointer;
}
.donaters_text p {
    width: 80%;
    margin: 0 auto;
    color: #cad52a;
    text-align: center;
    padding-bottom: 15px;
}
.donaters_text li {
    text-align: center;
    padding-bottom: 5px;
}
.donaters_text li:nth-child(2),
.donaters_text li:first-child{
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
}
.game_donaters::-webkit-scrollbar {
  width: 12px;               /* ширина scrollbar */
}
.game_donaters::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.9);       /* цвет дорожки */
}
.game_donaters::-webkit-scrollbar-thumb {
  background-color: #cad52a;    /* цвет плашки */
  border-radius: 20px;       /* закругления плашки */
  border: 3px solid rgba(0,0,0,0.9);  /* padding вокруг плашки */
}
@keyframes rainbow_animation {
    0%,100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}
  </style>